.backgroundlogin{
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background-image: url(..//resources/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.loginbox{
    width: 300px;
    height: 400px;
    background-color: #F0F4F7;
    border-radius: 20px;
    background: #F0F4F7;
    box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.25);
    color: #274F6A;
}