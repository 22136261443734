.sidenav{
    background-color: #F0F4F7;
    min-width: 180px;
    min-height: 100vh;
    height: 100%;
    font-size: 1.2em;
}



.asrstorelogo{
    background-image: url(..//resources/asrstorelogo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    height: 80px;
    width: 175px;
}

.buttonsize{
    width: 150px;
    height: 50px;
}

