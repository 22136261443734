.background{
    height: 100%;
    width: 100%;
    min-height: 100vh;
    font-size: 12px;
}

.contentbox{
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    color: #274F6A;
}

.table{
    font-size: 12px;
}

.body{
    font-size: 12px;
}

.fleft{
    float: left;
}


.fright{
    float: right;
}

.btnToType {
    border: none;
    color: blue;
    background-color: inherit;
    cursor: pointer;
    display: inline-block;
  }

.btnToType:hover {
    text-decoration: underline;
  }

.btnNoBackground{
    padding: 0;
    border: none;
    background: none;
    color: blue;
}

.w-40{
    width: 40%;
}


